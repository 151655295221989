import breadcrumbComp from "../../common/breadcrumb-comp";
import issueService from "./report-service.js";
import EncryptUtility from "../../utility/js/encrypt-utility";
import Utility from "../../../shared/utility.js";
import DisplayTextData from "../../common/display-text-data.vue";
import FeedbackForm from "@/components/common/feedback-comp.vue"

export default {
  data() {
    return {

      issueId: "",
      backSlash: true,
      changeBugStatusDialog: false,
      showDeleteDialog: false,
      customPage: true,
      file: "",
      files: "",
      fileList: [],
      noFiles: true,
      refresh: true,
      dragging: false,
      showFeedbackForm: false,
      images: [],
      userId: EncryptUtility.localStorageDecrypt("userID"),
      searchBugs: "",
      bugDialog: false,
      issueTitle: "",
      issueDescription: "",
      uploadFileName: "",
      url: "",
      isFormValid: false,
      bugData: [],
      filesTable: [],
      updatedHistoryTable: [],
      totalRecords: 0,
      max150Rule: [(v) => (v || "").length <= 150 || "Max Length of 150 character", (v) => !!v || "Field is required"],
      max1000Rule: [(v) => (v || "").length <= 1000 || "Max Length of 1000 character", (v) => !!v || "Field is required"],
      fileRules: [
        (files) => !files || !files.some((file) => file.size > 10e5) || "File size should be less than 10 MB!",
        (v) => !!v || "Field is required",
      ],
      bugHeaders: [
        { text: "Ticket Number", align: "start", value: "ticket_number", class: "primary customwhite--text" },
        { text: "Title", align: "start", value: "title", class: "primary customwhite--text" },
        { text: "Description", value: "description", class: "primary customwhite--text" },
        { text: "Date", value: "cdate", class: "primary customwhite--text" },
        { text: "Status", value: "status", class: "primary customwhite--text" },
        { text: "Actions", value: "actions", class: "primary customwhite--text" }
      ],
      updateHistoryHeader: [
        { text: "Status", align: "start", value: "status", class: "primary customwhite--text" },
        { text: "Updated Date", align: "start", value: "u_date_history", class: "primary customwhite--text" },
        { text: "Updated By", value: "u_by_history", class: "primary customwhite--text" },
        { text: "Comments", value: "comments", class: "primary customwhite--text" },
      ],
      displayTextArray: [],
      statusMap: {
        'New': { text: 'New', class: 'new' },
        'Duplicate': { text: 'Duplicate', class: 'duplicate' },
        'In-Progress': { text: 'In-Progress', class: 'inprogress' },
        'Dev-Fixed': { text: 'Dev-Fixed', class: 'devfix' },
        'In-Testing': { text: 'In-Testing', class: 'intest' },
        'Resolved': { text: 'Resolved', class: 'resolved' },
        'Closed': { text: 'Closed', class: 'closed' },
        'UserDeleted': { text: 'UserDeleted', class: 'userdelete' },
        'default': { text: 'Inactive', class: 'inactive' }
      },
      sortBy: "issue_id",
    };


  },
  async mounted() {
    this.getUserIssueDetails();
  },
  methods: {
    // Fucntion to get Status Text
    getStatusText(status) {
      return this.statusMap[status]?.text || this.statusMap.default.text;
    },
    // Function to get CSS class of the text
    getStatusClass(status) {
      return this.statusMap[status]?.class || this.statusMap.default.class;
    },
    //Reset Function
    resetFunction() {
      this.url = "",
        this.searchBugs = "";
      this.uploadFileName = "";
      this.issueTitle = "";
      this.issueDescription = "";
      this.totalRecords = 0;
      this.this.$refs.newIssueForm.resetValidation();
      this.bugDialog = false;
      this.getUserIssueDetails();
    },
    //Get Issue/Bug list
    async getUserIssueDetails() {
      this.bugData = [];
      this.totalRecords = 0;
      let pass = 1;
      let issueObj = {
        UserId: parseInt(this.userId),
        pass: parseInt(pass),
      };
      let showMessage = false;
      let issueTrackerData = await issueService.postIssueTracker("post", issueObj, showMessage);
      if (issueTrackerData !== null && issueTrackerData !== undefined && issueTrackerData.message !== "NA") {
        this.bugData = issueTrackerData.map((obj) => {
          return {
            ...obj,
            cdate: Utility.convertESTToLocal(obj.cdate),
          };
        });
        this.totalRecords = this.bugData.length;
      }
    },
    //Close the popup
    //Upload Screenshots button click event
    uploadFiles() {
      if (this.uploadFileName.length > 3) {
        let Alert = {
          type: "error",
          isShow: true,
          message: "Only two files are allowed",
        };
        this.$store.commit("ConfigModule/Alert", Alert);
      }
    },
    //Change event of the upload
    onChange(e) {
      this.files = e.target.files || e.dataTransfer.files;
      if (!this.files.length) {
        this.dragging = false;

        return;
      } else {
        this.noFiles = false;
        if (this.files.length > 3) {
          let Alert = {
            type: "error",
            isShow: true,
            message: "Max 3 files can be uploaded.",
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.noFiles = true;
          this.dragging = false;
          this.files = ""
        }
        for (let i = 0; i < this.files.length; i++) {
          this.createFile(this.files[i]);
        }
      }
    },
    //Checking file details
    createFile(file) {
      if (file.size > 15000000) {
        let Alert = {
          type: "error",
          isShow: true,
          message: "File size is more than 15MB",
        };
        this.$store.commit("ConfigModule/Alert", Alert);
        this.noFiles = true;
        this.dragging = false;
        return;
      }
      this.file = file;
      this.dragging = false;
      this.fileList.push(file)
    },
    //Removing dragged file
    removeFile(name) {
      this.fileList = this.fileList.filter((x) => x.name !== name)
      if (!this.fileList.length > 0) {
        this.noFiles = true;
        this.file = ""
        this.dragging = false;
      }
    },

    // Issue Details
    async issueDetails(item) {

      this.updatedHistoryTable = [];
      this.filesTable = [];
      this.issueData = [];
      this.issueData = item;
      this.changeBugStatusDialog = true;
      let detailsObj = {
        pass: 7,
        userId: 0,
        issue_id: item.issue_id
      }
      let issueTrackerData = await issueService.postIssueTracker("post", detailsObj, false);

      this.displayTextArray = [
        { label: "Ticket Number", value: issueTrackerData[0].ticket_number },
        { label: "Current Status", value: issueTrackerData[0]?.status },
        { label: "Reported By", value: issueTrackerData[0].created_by },
        { label: "Reported Date", value: Utility.convertESTToLocal(issueTrackerData[0].cdate) },
        { label: "Page Name", value: issueTrackerData[0].title },
        { label: "Page URL", value: issueTrackerData[0].url ? issueTrackerData[0].url : "" },
        { label: "Updated By", value: issueTrackerData[0].last_updated },
        { label: "Updated Date", value: Utility.convertESTToLocal(issueTrackerData[0].udate) }
      ]
      if (issueTrackerData[0]?.issue_updates !== null && issueTrackerData[0]?.issue_updates != undefined) {
        this.updatedHistoryTable = issueTrackerData[0]?.issue_updates
        this.updatedHistoryTable.forEach((element) => {
          element.u_date_history = Utility.convertESTToLocal(element.u_date_history);
        });
      }
      if (issueTrackerData[0]?.file_details !== null)
        this.filesTable = issueTrackerData[0]?.file_details
    },

    //Close the popup
    closeDialog() {
      this.issueTitle = "";
      this.url = "",
        this.removeFile()
      this.issueDescription = "";
      this.uploadFileName = "";
      this.$refs.newIssueForm.resetValidation();
      this.bugDialog = false;
    },
    //Download file function
    async downloadFile(item) {
      let showMessage = true;
      let downloadLink = await issueService.getIssueImages("get", item, showMessage);
      if (downloadLink !== undefined && downloadLink !== null) {
        window.open(downloadLink, "_blank").focus();
      }
    },

    async deleteFeedback() {
      let issueObj = {
        UserId: parseInt(this.userId),
        issue_id: this.issueId,
        pass: 6,
      };
      let showMessage = true;
      await issueService.postIssueTracker("post", issueObj, showMessage);
      this.showDeleteDialog = false
      this.getUserIssueDetails()
    },

    onClickDeleteIcon(issueId) {
      this.showDeleteDialog = true
      this.issueId = issueId
    },
    onClickReportIssue() {
      this.showFeedbackForm = true
    },
    closeFeedbackDialog() {
      this.showFeedbackForm = false
      this.getUserIssueDetails()
    },
  },

  components: {
    breadcrumbComp,
    DisplayTextData,
    FeedbackForm
  },
};
